import React, { useState } from "react";
import IconRightArrow from "../SvgIcons/IconRightArrow";
import { useToast } from "../../services/ToastProviderValidation";

const validateMobileNumber = (mobile) => {
  // Remove non-numeric characters (spaces, hyphens, +, etc.)
  const cleanedMobile = mobile.replace(/[^\d]/g, '');
  // Check if the cleaned mobile number is between 10 to 15 digits
  return cleanedMobile.length >= 10 && cleanedMobile.length <= 15;
};

const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};


function CareersForm() {
  const showToast = useToast();
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    AppEmail: "",
    ContactNo: "",
    Address: "",
    ApplyFor: "",
    DocumentCV: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiurl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { id, value, type, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === "file" ? files[0] : value,
    }));
  };

  const handleRadioChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      ApplyFor: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!formData.FirstName) {
      showToast("First Name is required.", "error");
      return;
    }
    if (!formData.AppEmail) {
      showToast("Email is required.", "error");
      return;
    }
    if (!validateEmail(formData.AppEmail)) {
      showToast("Invalid Email format.", "error");
      return;
    }

    if (!formData.ContactNo) {
      showToast("Contact Number is required.", "error");
      return;
    }
  
    // Check if ContactNo is in a valid format (and cleaned version is between 10 and 15 digits)
    if (!validateMobileNumber(formData.ContactNo)) {
      showToast("Invalid Contact Number format. It must be between 10 to 15 digits.", "error");
      return;
    } 

    if (!formData.ApplyFor) {
      showToast("Please select a position to apply for.", "error");
      return;
    }
    if (!formData.DocumentCV) {
      showToast("Please upload your resume.", "error");
      return;
    }

    setIsSubmitting(true);

    // Prepare data
    const formDataToSend = new FormData();
    formDataToSend.append("FirstName", formData.FirstName);
    formDataToSend.append("LastName", formData.LastName);
    formDataToSend.append("AppEmail", formData.AppEmail);
    formDataToSend.append("ContactNo", formData.ContactNo);
    formDataToSend.append("Address", formData.Address);
    formDataToSend.append("ApplyFor", formData.ApplyFor);
    formDataToSend.append("file", formData.DocumentCV);

    try {
      const response = await fetch(`${apiurl}/CareersForm`, {
        method: "POST",
        body: formDataToSend,
      });

      const result = await response.json();

      if (response.ok) {
        showToast("Form submitted successfully. Thank you!", "success");

        // Clear the form
        setFormData({
          FirstName: "",
          LastName: "",
          AppEmail: "",
          ContactNo: "",
          Address: "",
          ApplyFor: "",
          DocumentCV: "",
        });
        document.getElementById('DocumentCV').value = '';
      } else {
        showToast(result.error || "Form submission failed.", "error");
      }
    } catch (error) {
      showToast("An error occurred while submitting the form.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="careers-form mt100">
      <div className="container">
        <div className="row">
          <form
            className="col-12 col-md-12 col-lg-10 col-xl-8 mx-auto formcontroll needs-validation"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="First Name"
                  type="text"
                  className="form-control"
                  id="FirstName"
                  value={formData.FirstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="Last Name"
                  type="text"
                  id="LastName"
                  value={formData.LastName}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="Email"
                  type="email"
                  className="form-control"
                  id="AppEmail"
                  value={formData.AppEmail}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12 col-sm-6 formfield">
                <input
                  placeholder="Contact No."
                  type="tel"
                  className="form-control"
                  id="ContactNo"
                  value={formData.ContactNo}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-12 formfield">
                <input
                  placeholder="Address"
                  type="text"
                  name="Address"
                  id="Address"
                  className="form-control"
                  value={formData.Address}
                  onChange={handleChange}
                />
              </div>

              <div className="col-12 mt">
                <div className="selectoption text-center">
                  <h4>Apply For</h4>
                </div>
              </div>

              <div className="col-12 mt">
                <div className="ouropening-check d-flex justify-content-start justify-content-sm-center flex-wrap">
                  {[
                    "Full Stack Developer",
                    "Support Executive",
                    "Marketing Executive",
                    "Tally Programmers",
                  ].map((option, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="ApplyFor"
                        id={`inlineRadio${index + 1}`}
                        value={option}
                        onChange={handleRadioChange}
                        checked={formData.ApplyFor === option}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`inlineRadio${index + 1}`}
                      >
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-12 mx-auto d-block mt">
                <div className="row">
                  <div className="col-12 col-sm-8 col-md-5 mx-auto fileupload">
                    <input
                      type="file"
                      className="form-control"
                      id="DocumentCV"
                      onChange={handleChange}
                      accept=".pdf, .docx"
                    />
                    <label className="d-flex justify-content-center align-items-center">
                      + Upload Resume Here
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 mx-auto d-block mt">
                <div className="row">
                  <div className="col-12 col-sm-8 col-md-5 mx-auto pt50">
                    <button
                      className="btn col-12 btn-purpal"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <strong>Loading...</strong>
                      ) : (
                        <label>
                          Submit <IconRightArrow />
                        </label>
                      )}
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default CareersForm;
