import React, { useState } from "react";
import PageLayout from "../../component/PageLayout";
import GoogleTagManager from "../../component/GoogleTagManager";
import "./style.css";
import IconRightArrow from "../../component/SvgIcons/IconRightArrow";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios"; // make sure Axios is imported

export default function TellyprimeNow() {
  const [eventDetails, setEventDetails] = useState(null);
  const [eventDate, setEventDate] = useState(""); // State for eventDate
  const navigate = useNavigate();

  const handleRegisterNow = () => {
    navigate("/event-details", { state: { eventDetails, eventDate } });
    window.scrollTo({ top: 0 });
  };

  const fetchEvents = async (eventDate) => {
    try {
      const response = await Axios.post(
        "https://gshost.in/GS/EventAPI/event.php",
        {
          GSEvent: {
            event_date: eventDate,
          },
        }
      );
      const eventData = response.data.events || [];
      setEventDate(eventDate);
      setEventDetails(eventData);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  return (
    <div className="TallyPrimebg newreleaseTally">
      <GoogleTagManager />
      <PageLayout>
        <div className="filve-release-main">
          <div className="filve-Bg-right"></div>
          <div className="container">
            <div className="filve-release-inner">
              <div className="filve-release-left">
                <div className="filve-easyasimg">
                  <img
                    src="../images/banner-easyasimg.svg"
                    className="img-fluid"
                    alt="TallyPrime 5.0 is Available"
                  />
                </div>
                <div className="tally-prime-five">
                  <img
                    src="../images/tally-prime-5-0-logo.svg"
                    className="img-fluid"
                    alt="TallyPrime 5.0 is Available"
                  />
                </div>
                <ul>
                  <li>Lightning fast GST uploads</li>
                  <li>One click GST reconciliation</li>
                  <li>100% control on ITC risks</li>
                  <li>Automated ledger creation</li>
                </ul>
              </div>
              <div className="filve-release">
                <img
                  src="../images/home-tallyprimerel5.svg"
                  className="img-fluid"
                  alt="TallyPrime 5.0 is Available"
                />
              </div>
            </div>
            <div className="button d-flex gap50">
              <a
                href="images/satup/tallyprime_setup5-1.exe"
                download
                className="btn btn-danger"
              >
                <label>
                  Download Now <IconRightArrow />
                </label>
                <span></span>
              </a>
              <Link
                to="/contact"
                onClick={() => {
                  window.scrollTo({ top: 0 });
                }}
                className="btn btn-dark"
              >
                <label>
                  Contact Us Now <IconRightArrow />
                </label>
                <span></span>
              </Link>
              <button onClick={handleRegisterNow} className="btn btn-danger">
                <label>
                  Register for Webinar
                  <IconRightArrow />
                </label>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="announce-title text-center">
            <div className="titletwo">
              <h2>
                We're excited to announce the gold launch of{" "}
                <span>TallyPrime Release 5.1.</span>
              </h2>
              <h3>
                It’s now available for download. If you have a valid TSS, you
                can start using it right away.
              </h3>
              <p>
                <strong>TallyPrime Release 5.1</strong> comes with the following
                new features:
              </p>
            </div>
          </div>

          <section className="peragraf-content">
            <div className="listcontent listcontent1">
              <div className="experience-pic">
                <img
                  src="../images/tellyprime-now/1.svg"
                  alt="Connected GST Experience"
                />
              </div>
              <div className="experience-info">
                <h5>Connected GST Experience</h5>
                <p>
                  Transform your GST upload and filing experience with powerful
                  integration and automation capabilities. Upload GSTR-1 and
                  GSTR-3B data, file GSTR-1, and auto-reconcile GSTR-2A/2B – all
                  without visiting the GST portal. Built-in error detection and
                  correction ensure precise filings, giving you the confidence
                  and accuracy.
                </p>
              </div>
            </div>

            <div className="listcontent listcontent2">
              <div className="experience-pic">
                <img
                  src="../images/tellyprime-now/5.svg"
                  alt="One Click GST Reconciliation"
                />
              </div>
              <div className="experience-info">
                <h5>One Click GST Reconciliation</h5>
                <p>
                  With a single click, TallyPrime automatically downloads and
                  reconciles GSTR-2A/2B, GSTR-1, and GSTR-3B. Get comprehensive
                  summaries of reconciled transactions and mismatches. Quickly
                  reconcile multiple returns, GSTINs, and periods to save time
                  and boost efficiency.
                </p>
              </div>
            </div>

            <div className="listcontent listcontent3">
              <div className="experience-pic">
                <img
                  src="../images/tellyprime-now/2.svg"
                  alt="100% Control on ITC Risks"
                />
              </div>
              <div className="experience-info">
                <h5>100% Control on ITC Risks</h5>
                <p>
                  Safeguard ITC with real-time risk monitoring and proactive
                  management tools. View ITC at risk in the payable report, get
                  precomputed payment suggestions, and keep your ITC status up
                  to date with dynamic reconciliation insights. Reduce potential
                  risks to tax credits and manage your working capital more
                  effectively.
                </p>
              </div>
            </div>
            <div className="listcontent listcontent4">
              <div className="experience-pic">
                <img
                  src="../images/tellyprime-now/3.svg"
                  alt="Automated Ledger Creation"
                />
              </div>
              <div className="experience-info">
                <h5>Automated Ledger Creation</h5>
                <p>
                  Easily create a ledger by fetching party details from the GST
                  portal using just the GSTIN. GSTIN details are retrieved from
                  the portal and populated into the ledger data, saving time and
                  reducing errors. You can create single or bulk ledgers with
                  ease. Additionally, check the party’s GST filing status to
                  assess their compliance health and take appropriate actions.
                </p>
              </div>
            </div>

            <div className="listcontent listcontent5">
              <div className="experience-pic">
                <img
                  src="../images/tellyprime-now/4.svg"
                  alt="Simplified TDS 194Q Compliance"
                />
              </div>
              <div className="experience-info">
                <h5>Simplified TDS 194Q Compliance</h5>
                <p>
                  For businesses with a turnover exceeding INR 10 crore from the
                  previous financial year and payments exceeding 50 lakh for
                  goods in the current year, the latest release helps you
                  efficiently manage TDS deductions for ‘Purchase of Goods’ and
                  ensure regulatory compliance.
                </p>
              </div>
            </div>

            <div className="listcontent" style={{ textAlign: "center" }}>
              <p>
                The latest release is available for TallyPrime, TallyPrime Edit
                Log, TallyPrime Server, TallyPrime Developer, and TallyPrime
                powered by AWS.
              </p>
            </div>
          </section>
        </div>
        <section>
          <div className="container">
            <div className="simplemanagment-Management">
              <div className="titletwo text-center">
                <h2>
                  Business Management - <span>Easy as 123!</span>
                </h2>
              </div>
              <div className="simplemanagment d-flex justify-content-between gap-5">
                <div className="simplemanagment-left">
                  <p>
                    <strong>Simple Accounting Management </strong>{" "}
                  </p>
                  <ul>
                    <li>
                      Pre-defined accounting groups and flexible chart of
                      accounts
                    </li>
                    <li>Groups and ledgers management</li>
                    <li>Multi-currency support</li>
                    <li>Multiple companies </li>
                    <li>Group company/consolidation of companies</li>
                    <li>Post-dated transactions </li>
                    <li>
                      Multiple voucher numbering series for a voucher type
                    </li>
                  </ul>

                  <p>
                    <strong>Flexible Purchase & Sales Management </strong>{" "}
                  </p>
                  <ul>
                    <li>GST compliant invoice (Tax Invoice, Bill of Supply)</li>
                    <li>Multiple billing formats </li>
                    <li>Multiple price list & discount management</li>
                    <li>Multiple mailing address</li>
                    <li>Sales & Purchase orders processing</li>
                    <li>
                      Complete track of receipt (GRN) and delivery note (DC)
                    </li>
                  </ul>

                  <p>
                    <strong>Inventory Management</strong>{" "}
                  </p>
                  <ul>
                    <li>Unlimited stock groups and categorization </li>
                    <li>Multi-Locations/godowns and batch management</li>
                    <li>Alternate & compound units of measure</li>
                    <li>Physical stock verification </li>
                    <li>Manufacture and expiry date management</li>
                    <li>Flexible units of measure</li>
                    <li>Job costing</li>
                    <li>Item cost tracking</li>
                    <li>Re-order level</li>
                    <li>Multiple stock valuation</li>
                  </ul>

                  <p>
                    <strong>Credit and Cash Flow Management</strong>{" "}
                  </p>
                  <ul>
                    <li>Receivable and payable management </li>
                    <li>Multiple bill settlement</li>
                    <li>Ageing analysis</li>
                    <li>Credit control utilities </li>
                    <li>Cash and funds flow</li>
                    <li>Cash flow projection</li>
                    <li>Interest calculations </li>
                    <li>Payment performance of debtors </li>
                  </ul>

                  <p>
                    <strong>Banking</strong>{" "}
                  </p>
                  <ul>
                    <li>Auto configured cheque printing </li>
                    <li>Chequebook management</li>
                    <li>Auto bank reconciliation (Auto BRS)</li>
                    <li>Post-dated cheque management</li>
                    <li>Payment advice & deposit slip</li>
                    <li>E-payments</li>
                  </ul>

                  <p>
                    <strong>Manufacturing & Job Work</strong>{" "}
                  </p>
                  <ul>
                    <li>Multi bill of material (BOM)</li>
                    <li>Manufacturing journal</li>
                    <li>Job order processing </li>
                    <li>Job work processing</li>
                  </ul>

                  <p>
                    <strong>Payroll Capabilities</strong>{" "}
                  </p>
                  <ul>
                    <li>Multi pay slip printing and e-mailing</li>
                    <li>Employee category/group-wise Pay Sheet</li>
                    <li>Employee profile, group & category</li>
                    <li>Employee pay structure management </li>
                    <li>Attendance recording & pay process </li>
                    <li>Payroll accounting</li>
                    <li>Employee income tax with reports</li>
                    <li>PF/ESI calculation & e-returns</li>
                    <li>Payroll exception reports </li>
                    <li>Employee profile report</li>
                    <li>Payment advice</li>
                    <li>Payroll statutory summary</li>
                  </ul>
                </div>

                <div className="simplemanagment-left">
                  <p>
                    <strong>
                      Statutory Capabilities with Connected Experiences
                    </strong>{" "}
                    <span className="arrow-downNew">New</span>{" "}
                  </p>
                  <ul>
                    <li>Manage GST compliance</li>
                    <li>Multi-GSTIN support in a single company </li>
                    <li>Upload and filing of GSTR-1</li>
                    <li>Upload of GSTR-3B</li>
                    <li>GSTR-1 reconciliation </li>
                    <li>GSTR-2B and GSTR-2A Reconciliation</li>
                    <li>Annual GST computation for GSTR 9</li>
                    <li>Track and report amendments</li>
                    <li>CMPO8 and GSTR 4 support for composite dealers </li>
                    <li>Tax deducted at source (TDS) </li>
                    <li>Tax collected at source (TCS) </li>
                  </ul>

                  <p>
                    <strong>e-Invoicing</strong>{" "}
                  </p>
                  <ul>
                    <li>Connected with IRP </li>
                    <li>Single and bulk e-lnvoice generation</li>
                    <li>e-Invoice cancellation </li>
                    <li>
                      Prevention, detection and correction to manage exceptions{" "}
                    </li>
                    <li>e-Invoice report</li>
                    <li>IRN register</li>
                  </ul>

                  <p>
                    <strong>e-Way Bill Connectivity</strong>{" "}
                  </p>
                  <ul>
                    <li>Connected with NIC portal</li>
                    <li>Single or bulk e-Way Bill generation</li>
                    <li>Track e-Way Bill related activities</li>
                    <li>Preview and Summary Reports</li>
                    <li>Easy detection and correction of errors</li>
                  </ul>

                  <p>
                    <strong>MSME Payments</strong>{" "}
                  </p>
                  <ul>
                    <li>Tracking of MSME payments and returns </li>
                  </ul>

                  <p>
                    <strong>Cost Control and Cost Analysis</strong>{" "}
                  </p>
                  <ul>
                    <li>Cost centre and profit centre management</li>
                    <li>Set budgets & track variance</li>
                    <li>Business forecasting using scenario management</li>
                  </ul>

                  <p>
                    <strong>Business Reports</strong>{" "}
                  </p>
                  <ul>
                    <li>Discover easily, do more with GoTo/ SwitchTo</li>
                    <li>
                      Tailor-made reports with Change View, Basis of Values and
                      Exception reports
                    </li>
                    <li>Reports save functionality </li>
                    <li>
                      Filter option in Reports / Reports filter in all reports
                    </li>
                  </ul>

                  <p className="yellow-color">
                    <strong>Accounting Reports</strong>{" "}
                  </p>
                  <ul>
                    <li>Ledger reports </li>
                    <li>Cash/Bank books</li>
                    <li>Purchase/Sales register (columnar)</li>
                    <li>Bills receivables</li>
                    <li>Bills payables</li>
                  </ul>

                  <p className="yellow-color">
                    <strong>Financial Report</strong>{" "}
                  </p>
                  <ul>
                    <li>Balance sheet</li>
                    <li>Profit & loss A/c</li>
                    <li>Trial balance</li>
                    <li>Ratio analysis </li>
                  </ul>

                  <p className="yellow-color">
                    <strong>Inventory Report</strong>{" "}
                  </p>
                  <ul>
                    <li>Stock summary</li>
                    <li>Stock ageing analysis</li>
                    <li>Movement analysis </li>
                    <li>Stock transfers</li>
                    <li>Stock item cost analysis</li>
                    <li>Location/Godown summary</li>
                    <li>Stock item-wise profitability</li>
                    <li>Order summary</li>
                    <li>Batch summary (Mfg. & Exp. date)</li>
                    <li>Re-order status reports</li>
                    <li>Job costing analysis </li>
                    <li>Job-work reports </li>
                  </ul>
                </div>

                <div className="simplemanagment-left">
                  <p className="yellow-color">
                    <strong>Management Control Reports</strong>{" "}
                  </p>
                  <ul>
                    <li>Cost centre</li>
                    <li>Cost category</li>
                    <li>Budget </li>
                    <li>Cash flow report and projection</li>
                    <li>Scenario management</li>
                    <li>Comparative reports</li>
                  </ul>

                  <p>
                    <strong>Delightful User Experience</strong>{" "}
                  </p>
                  <ul>
                    <li>On the fly feature enablement and ledger creation </li>
                    <li>
                      Easily configure ledgers and vouchers with More Details{" "}
                    </li>
                    <li>
                      Consistent top menu that helps with multi- tasking and
                      better navigation
                    </li>
                    <li>
                      Contextual right bar that helps get used to and take
                      advantage of the product
                    </li>
                    <li>Intelligent setup manager for easy troubleshooting</li>
                    <li>About page for easily getting support</li>
                  </ul>

                  <p>
                    <strong>Internet-based Capabilities</strong>{" "}
                  </p>
                  <ul>
                    <li>Tally Reports in Browser</li>
                    <li>Secure remote access</li>
                    <li>E-mailing of invoices and reports</li>
                    <li>Integration with payment gateways </li>
                    <li>Help & support</li>
                    <li>License and user management</li>
                    <li>TallyShop</li>
                  </ul>

                  <p>
                    <strong>Edit Log</strong>{" "}
                  </p>
                  <ul>
                    <li>
                      Tracks activities such as creation, alteration, and
                      deletion for transactions and masters
                    </li>
                    <li>Get to know when and who made the changes</li>
                    <li>Compare and know the version difference</li>
                    <li>Filter the edited or deleted transaction</li>
                  </ul>

                  <p>
                    <strong>Data Exchange Capabilities </strong>{" "}
                  </p>
                  <ul>
                    <li>On-demand data synchronization</li>
                    <li>
                      Export reports in different formats like Excel, PDF, JPEG
                      and HTML
                    </li>
                    <li>Export pivot for sales/purchase register</li>
                    <li>Export & import of data through XML</li>
                    <li>Tally ODBC</li>
                    <li>Upload data to HTTP web server</li>
                  </ul>

                  <p>
                    <strong>Data Security Capabilities </strong>{" "}
                  </p>
                  <ul>
                    <li>Data backup & restore</li>
                    <li>Multiple security controls </li>
                    <li>User management </li>
                    <li>Password policy management</li>
                    <li>TallyVault</li>
                    <li>TallyAudit</li>
                  </ul>

                  <p>
                    <strong>Other Business Capabilities</strong>{" "}
                  </p>
                  <ul>
                    <li>Dongle-based digital signature</li>
                    <li>Digital signature on invoices and reports</li>
                    <li>Edit Log for audit trail</li>
                    <li>Logo printing</li>
                    <li>Date based reporting </li>
                    <li>Flexible financial periods</li>
                    <li>Split financial year</li>
                    <li>Flexible voucher numbering</li>
                    <li>User defined voucher types</li>
                    <li>Multi-language support</li>
                    <li>Percentage based reporting</li>
                    <li>Multi account reporting</li>
                    <li>Context sensitive help</li>
                    <li>Data migration from earlier versions of Tally </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </PageLayout>
    </div>
  );
}
