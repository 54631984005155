import React from "react";
import IconMobileAppSolution from "../SvgIcons/IconMobileAppSolution";
import IconTallyConnect from "../SvgIcons/IconTallyConnect";
import IconTallyEnterpriseSolution from "../SvgIcons/IconTallyEnterpriseSolution";
import IconTallyIntegrators from "../SvgIcons/IconTallyIntegrators";
import IconTallySales from "../SvgIcons/IconTallySales";
import "./style.css";

export default function WhatWeOffer() {
  const WhatweData = [
    {
      id: 1,
      icon: <IconTallySales />,
      title: "Tally Sales and Service",
      description: "Explore seamless solutions with our Tally Sales and Service Support.",
    },
    {
      id: 2,
      icon: <IconTallyEnterpriseSolution />,
      title: "Tally Enterprise Solution",
      description: "Discover unparalleled solutions with our Tally Enterprise Solution.",
    },
    {
      id: 3,
      icon: <IconTallyIntegrators />,
      title: "Tally Integrators",
      description: "Unlock advanced ERP solutions with our Tally Integration Solutions.",
    },
    {
      id: 4,
      icon: <IconTallyConnect />,
      title: "Tally Connect",
      description:
        "In the era of digital adoption, geographical challenges in the distribution of goods persist. Tally Connect addresses this by seamlessly supporting businesses with diverse needs.",
    },
    {
      id: 5,
      icon: <IconMobileAppSolution />,
      title: "Tally Integrated Mobile App Solution",
      description:
        "Elevate your business with our Tally Integrated Mobile App Solution Partner. Benefit from expert guidance, sales support, and dedicated service for seamless integration.",
    },
  ];

  return (
    <section className="pb200 pt100 whatweoffer">
      <div className="container">        
            <div className="titletwo text-center" data-aos="fade-up">
              <h2>
                What we <span>offer?</span>
              </h2>
            </div>
          
        <div className="whatweoffer-main">
          {WhatweData.map((item, index) => (
            <div
            key={item.id}
            className="whatofferBox box-default"
            data-aos="flip-down" 
            //data-aos-delay={index * 0.1}
            // data-aos-anchor-placement="top-center"
          >
            <span>{item.icon}</span>
            <div className="information">
              <h4>{item.title}</h4>
              <p>{item.description}</p>
            </div>
          </div>
          ))}
        </div>
      </div>
    </section>
  );
}
