import React from "react";
import CountUp from "react-countup";
import { CardBody, CardContainer, CardItem } from ".";

export function ThreeDCardDemo({ number, suffix, text, imgtag }) {
  return (
    <CardContainer className="inter-var">
      <CardBody>
        {number && (
          <div className="mainclass py-20">
            {/* CountUp animation for the number */}
            <CardItem as="span" translateZ="50">
              <CountUp start={0} end={number} duration={2} />
              {suffix}
            </CardItem>

            {/* Displaying the text */}
            {text && (
              <CardItem as="p" translateZ="60">
                {text}
              </CardItem>
            )}
          </div>
        )}

        {/* Optional image rendering */}
        {imgtag && (
          <CardItem translateZ="100">
            <img src={imgtag} alt="3D card visual" />
          </CardItem>
        )}
      </CardBody>
    </CardContainer>
  );
}
