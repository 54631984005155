import React, { createContext, useContext } from "react";
import { toast, Toaster } from "react-hot-toast";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProviderValidation = ({ children }) => {
  const showToast = (message, type = "success") => {
    if (type === "success") {
      toast.success(message);
    } else if (type === "warning") {
      toast(message, {
        icon: "⚠️",
        style: {
          background: "#fef08a",
          color: "#92400e",
        },
      });
    } else if (type === "info") {
      toast(message, {
        icon: "ℹ️",
        style: {
          background: "#bfdbfe",
          color: "#1e3a8a",
        },
      });
    } else if (type === "error") {
      toast.error(message);
    } else {
      toast(message); // Default
    }
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <Toaster position="top-center" />
    </ToastContext.Provider>
  );
};
