import React from 'react';
import { Logopictures } from './LogoPic';
import './style.css';
import { ThreeDCardDemo } from '../MagicUi/CardEffect/ThreeDCardDemo';

export default function OurClient() {
  return (
    <section className='OurClient text-center' data-aos="fade-up">
      <div className='container'>
        <div className="titletwo">
          <h2>Our <span>Clients</span></h2>
        </div>
        <div className="clients-main">
             {/* <div data-aos="fade-up" data-aos-anchor-placement="center-bottom" key={clientlogo.id} className='slide-logos'><img src={clientlogo.img} alt="" className='img-fluid' /></div>*/}
            {Logopictures.map((clientlogo) => (              
              <ThreeDCardDemo data-aos-anchor-placement="center-bottom" imgtag={clientlogo.img}/>
            ))}          
        </div>
      </div>
    </section>
  );
}
