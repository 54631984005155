import React from "react";
import GoogleTagManager from "../../component/GoogleTagManager";
import HomeBanner from "../../component/HomeBanner";
import IndustriesWeServer from "../../component/IndustriesWeServer";
import OurClient from "../../component/OurClient";
import OurTechnology from "../../component/OurTechnology";
import PageLayout from "../../component/PageLayout";
import TallyAppsTabs from "../../component/TallyAppsTabs";
import TallyPrimeRelease from "../../component/TallyPrimeRelease";
import TallyProducts from "../../component/TallyProducts";
import WhatWeOffer from "../../component/WhatWeOffer";
import Counter from "../../component/counter";
import "./style.css";

export default function Home() {
 
  return (
    <div className="home-main">
      <div className="arrowhome"/>
      <GoogleTagManager />
      <PageLayout>
        <HomeBanner/>
        <Counter />        
        <WhatWeOffer />
        <TallyProducts />
        <TallyAppsTabs />
        <TallyPrimeRelease />
        <IndustriesWeServer />
        <OurTechnology />
        <OurClient />
      </PageLayout>
    </div>
  );
}
