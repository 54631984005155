import React, { useState, useEffect, useRef } from "react";
import { ThreeDCardDemo } from "../MagicUi/CardEffect/ThreeDCardDemo";
import "./style.css";

export default function Counter() {
  const [activeIndex, setActiveIndex] = useState(-1);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setActiveIndex(0);
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const items = [
    { id: 1, end: 22, suffix: "Y", text: "Experience" },
    { id: 2, end: 12, suffix: "K+", text: "Happy Customers" },
    { id: 3, end: 50, suffix: "+", text: "Employees" },
    { id: 4, end: 4, suffix: "", text: "Countries" },
    { id: 5, end: 5400, suffix: "+", text: "Projects Completed" },
    { id: 6, end: 16, suffix: "+", text: "Countries Served" },
  ];

  return (
    <section className="box-default counter-main" ref={sectionRef}>
      <div className="countupmain">
        {items.map((item, index) => (
          <div key={item.id} className="counterbox">
            {/* Only render ThreeDCardDemo when section is in view */}
            {activeIndex === 0 && (
              <ThreeDCardDemo
                number={item.end}
                suffix={item.suffix}
                text={item.text}
              />
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
