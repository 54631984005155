import React from "react";
import "./style.css";
import HomeWaves from "../SvgIcons/HomeWaves";

export default function HomeBanner() {
  return (
   <div className="banner-animationeffect">
    <section className="slider-main">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-sm-center justify-content-md-start justify-content-center align-items-center">
            <div
              className="company-info d-flex flex-column">
              <div className="hometitle d-flex flex-column">
                <label>Welcome to</label>
                <h1>
                  <p>
                    <span>G</span>lobal
                  </p>
                  <span>S</span>oftware
                </h1>
              </div>
              <div className="partners d-flex justify-content-sm-center justify-content-md-start justify-content-center">
                <img
                  src="../images/5-star.png"
                  alt="Tally Partner 5 Star"
                  className="img-fluid"
                />                
              </div>
            </div>
          </div>
          <div class="filve-release">
            <img src="../images/home-tallyprimerel5.svg" class="img-fluid" alt="TallyPrime 5.0 is Available"/></div>
        </div>        
      </div>  
      <div class="blockAvailable"><a href="/tallyprime-now">TallyPrime 5.1 is Now Available</a></div>
    </section>
    <HomeWaves/>
    </div> 
  );
}
