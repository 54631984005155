import React from "react";

export default function HomeWaves() {
  return (
    <svg
      className="hero-waves"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 24 150 28 "
      preserveAspectRatio="none"
    >
      <defs>
        <path
          id="wave-path"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        ></path>
      </defs>
      <g className="wave1">
        <use href="#wave-path" x="50" y="3"></use>
      </g>
      <g className="wave2">
        <use href="#wave-path" x="50" y="0"></use>
      </g>
      <g className="wave3">
        <use href="#wave-path" x="50" y="9"></use>
      </g>
    </svg>
  );
}
